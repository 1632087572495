// State Types
export interface AuthState {
  isLoading: boolean
  isSigned: boolean
  headers: any | null
  isAuthenticationExpired: boolean
  signFailureMessage: string
}

// Action Types

export const SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST'
export const SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS'
export const SIGN_FAILURE = '@auth/SIGN_FAILURE'
export const SIGN_OUT = '@auth/SIGN_OUT'
export const AUTHENTICATION_EXPIRED = '@auth/AUTHENTICATION_EXPIRED'
export const UPDATE_AUTH_HEADERS = '@auth/UPDATE_AUTH_HEADERS'

export interface SignInRequestAction {
  type: typeof SIGN_IN_REQUEST
  payload: any
}

export interface SignInSuccessAction {
  type: typeof SIGN_IN_SUCCESS
  payload: any
}

export interface SignFailureAction {
  type: typeof SIGN_FAILURE
  payload?: string
}

export interface SignOutAction {
  type: typeof SIGN_OUT
}

export interface AuthenticationExpiredAction {
  type: typeof AUTHENTICATION_EXPIRED
}

export interface UpdateAuthHeadersAction {
  type: typeof UPDATE_AUTH_HEADERS
  payload: any
}

export type AuthActionTypes =
  | SignInRequestAction
  | SignInSuccessAction
  | SignFailureAction
  | SignOutAction
  | AuthenticationExpiredAction
  | UpdateAuthHeadersAction
